<template>
  <div>
    <ModalCreateAll
      v-if="showCreateAllInvoices"
      :showModal="showCreateAllInvoices"
      :onClickCancel="hideModal"
      @createAllConfirmed="createMonthlyInvoice(0)"
    />

    <div>
      <label class="checkbox"
        ><input type="checkbox" v-model="hideZeroFee" /> Hide 0 Euro</label
      >
    </div>

    <table v-if="locations.length > 0" class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>Location</th>
          <th>Channels</th>
          <th>Amount to pay (excl VAT)</th>
          <th width="100" class="has-text-right">
            <a
              @click="setShowCreateAllInvoices"
              class="button is-primary"
              :enabled="!isCreatingInvoice"
              >Create all</a
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(location, index) in filteredLocations" :key="index">
          <td>
            <a @click="goToLocation(location.Id)">{{ location.Name }}</a>
          </td>
          <td>
            <span
              v-for="(channel, index) in getChannels(location.Channels)"
              :key="index"
              class="tag has-margin-right is-link"
            >
              <span>{{ channel }}</span>
            </span>
          </td>
          <td class="has-text-right" width="200">
            {{ location.AmountToPay | toCurrency }}
          </td>
          <td width="150" class="has-text-right">
            <a
              @click="createMonthlyInvoice(location.Id)"
              class="has-icon"
              title="Create invoice"
            >
              <span
                class="icon"
                :class="{ 'is-loading': location.Id === isCreatingInvoice }"
              >
                <font-awesome-icon :icon="['fa', 'plus']" />
              </span>
              <span
                :class="{ 'has-text-grey': location.Id === isCreatingInvoice }"
                >Create</span
              >
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

const ModalCreateAll = () =>
  import('@/components/FeeInvoices/ModalCreateAllInvoices')

export default {
  components: {
    ModalCreateAll,
  },

  props: {},

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isCreatingInvoice: 0,
      showCreateAllInvoices: false,
      hideZeroFee: false,
    }
  },

  computed: {
    ...mapState('feeinvoiceStore', ['settings', 'locations']),

    nrOfInvoices() {
      return this.locations.length
    },

    filteredLocations() {
      let locations = this.locations

      if (this.hideZeroFee) {
        locations = locations.filter((l) => l.AmountToPay > 0)
      }

      return locations
    },
  },

  watch: {
    nrOfInvoices: function(newValue) {
      if (newValue === 0) {
        this.isCreatingInvoice = 0
      }
    },
  },

  created() {},

  methods: {
    ...mapMutations('feeinvoiceStore', ['setLocations', 'setInvoices']),
    ...mapActions('feeinvoiceStore', ['createInvoices']),

    createMonthlyInvoice(locationId) {
      let self = this

      if (self.isCreatingInvoice !== locationId) {
        self.isCreatingInvoice = locationId

        self.createInvoices({
          channelId: self.channelId,
          year: self.settings.year,
          month: self.settings.month,
          locationId: locationId,
        })
      }
    },

    getChannels(channels) {
      let c = []

      if (channels && channels.length > 0) {
        c = channels.split(',')
      }

      return c
    },

    goToLocation(locationId) {
      this.$router.push({ path: `/locations/${locationId}` })
    },

    setShowCreateAllInvoices() {
      this.showCreateAllInvoices = true
    },

    hideModal() {
      this.showCreateAllInvoices = false
    },
  },
}
</script>

<style></style>
